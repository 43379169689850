<div
  #mainLayout
  id="main-layout"
  fxLayout="column"
  (swiperight)="onSwipeRight($event)"
  (panright)="onPanRight($event)"
  (panend)="onPanEnd($event)"
  (pandown)="onPanDown($event)"
  (swipedown)="onSwipeDown($event)"
>
  <div
    #backGroundColorForScrolling
    style="
      background-color: #3f51b5;
      position: fixed;
      top: 0;
      margin-top: -40px;
      width: 100%;
      height: 40px;
      z-index: 2;
    "
  >
    <!-- Keep the theme color under the iOS status bar -->
  </div>
  <ng-container *ngIf="authenticated; else notAuthenticated">
    @if (currentUser()) {
      <mat-toolbar
        color="primary"
        class="mat-elevation-z5 no-print"
        style="position: sticky; z-index: 2; top: 0; padding: 0 8px"
      >
        <div class="flex flex-row justify-between w-[100%] items-center">
          <!-- Smaller Device Menu and Back Button -->
          <div class="flex-1 flex-grow-0 xl:hidden">
            <ng-container *ngIf="showBackNavButton$ | async; else noBackNavButton">
              <ng-container *ngIf="backButtonLabel$ | async as backButtonLabel; else noBackLabel">
                <div fxHide fxShow.lt-lg>
                  <a mat-button (click)="back()">
                    <mat-icon>arrow_back_ios</mat-icon>{{ backButtonLabel }}
                  </a>
                </div>
              </ng-container>
              <ng-template #noBackLabel>
                <div fxHide fxShow.lt-lg>
                  <a mat-icon-button (click)="back()">
                    <mat-icon>arrow_back_ios</mat-icon>
                  </a>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #noBackNavButton>
              <div fxHide fxShow.lt-lg style="display: flex; align-items: center">
                <a mat-icon-button *ngIf="!sidenavOpened" (click)="toggleSidenav()">
                  <mat-icon style="vertical-align: baseline">menu</mat-icon>
                </a>
                <a mat-icon-button *ngIf="sidenavOpened" (click)="toggleSidenav()">
                  <mat-icon style="vertical-align: baseline">menu_open</mat-icon>
                </a>
              </div>
            </ng-template>
          </div>

          <!-- Breadcrumbs -->
          <div class="flex justify-center xl:hidden">
            <!-- Middle Section Center Aligned -->
            <div class="flex-grow-0 flex items-center">
              <!--
              <button *ngIf="!(showBackNavButton$ | async)" mat-button style="height: 40px; padding-right: 4px;">{{label$ | async}}</button>
              -->
              <app-breadcrumbs
                [maxLabelSegmentLength]="18"
                [maxSegments]="breadcrumbSegments$ | async"
                style="flex: 1; flex-grow: 0"
              >
              </app-breadcrumbs>
              <!--
              <nav mat-tab-nav-bar color="primary" backgroundColor="primary">
                <a mat-tab-link *ngIf="!(showBackNavButton$ | async)">
                  {{label$ | async}}
                </a>
              </nav>
              -->
            </div>
          </div>

          <!-- Far Left -->
          <div class="xl:flex hidden">
            <!-- Middle Section Left Aligned -->

            <nav
              mat-tab-nav-bar
              [tabPanel]="tabPanel"
              [mat-stretch-tabs]="false"
              color="primary"
              backgroundColor="primary"
              fxShow
              fxHide.lt-lg>
              <!-- <div> -->
              <a mat-tab-link routerLink="dashboard" fxShow fxHide.lt-lg>
                <mat-icon>home</mat-icon>&nbsp;&nbsp;{{ appName }}
              </a>

                <a
                    mat-tab-link
                    routerLink="trucks"
                    [active]="isLinkActive('trucks')"
                    *appIfHasPrivileges="['READ_TRUCKS', 'UI_VIEW_APP_TRUCKS']"
                    >Trucks</a
                  >
                  <!--
                  <mat-menu #dashboardMenu="matMenu">
                    <a mat-menu-item routerLink="dashboard/operator">Operator Dashboard</a>
                  </mat-menu>
                  <a
                    mat-tab-link
                    [matMenuTriggerFor]="dashboardMenu"
                    *appIfHasPrivileges="['READ_TRUCKS', 'UI_VIEW_APP_TRUCKS']"
                  >Dashboards</a>
                  -->
                  <mat-menu #schedulerMenu="matMenu">
                    <a mat-menu-item routerLink="job-scheduler" *appIfHasPrivileges="['UI_VIEW_APP_JOB_SCHEDULER']">Job Scheduler</a>
                    <a mat-menu-item routerLink="assignment-scheduler" *appIfHasPrivileges="['UI_VIEW_APP_ASSIGNMENT_SCHEDULER']">Assignment Scheduler</a>
                  </mat-menu>
                  <a mat-tab-link [matMenuTriggerFor]="schedulerMenu" *appIfHasPrivileges="['UI_VIEW_MENU_BOOKING']">Booking</a>
                  <a
                    mat-tab-link
                    routerLink="customers"
                    [active]="isLinkActive('customers')"
                    *appIfHasPrivileges="['READ_CUSTOMERS', 'UI_VIEW_APP_CUSTOMERS']"
                    >Customers</a
                  >
                  <a
                    mat-tab-link
                    routerLink="projects"
                    [active]="isLinkActive('projects')"
                    *appIfHasPrivileges="['READ_PROJECTS', 'UI_VIEW_APP_PROJECTS']"
                    >Projects</a
                  >
                  <a
                    mat-tab-link
                    routerLink="jobs"
                    [active]="isLinkActive('jobs')"
                    *appIfHasPrivileges="['READ_JOBS', 'UI_VIEW_APP_JOBS']"
                    >Jobs</a
                  >
                  <a
                    mat-tab-link
                    routerLink="assignments"
                    [active]="isLinkActive('assignments')"
                    *appIfHasPrivileges="['READ_ASSIGNMENTS', 'UI_VIEW_APP_ASSIGNMENTS']"
                    >Assignments</a
                  >
                  <a
                    mat-tab-link
                    routerLink="delivery-slips"
                    [active]="isLinkActive('delivery-slips')"
                    *appIfHasPrivileges="['READ_DELIVERY_SLIPS', 'UI_VIEW_APP_DELIVERY_SLIPS']"
                    >Delivery Slips</a
                  >
                  <mat-menu #invoicing="matMenu">
                    <a
                      mat-menu-item
                      routerLink="invoices"
                      *appIfHasPrivileges="['READ_INVOICES', 'UI_VIEW_APP_INVOICES']"
                      >Invoices</a
                    >
                    <a
                      mat-menu-item
                      routerLink="invoices/create"
                      *appIfHasPrivileges="['WRITE_INVOICES']"
                      >Create Invoices</a
                    >
                    <ng-container *appIfHasPrivileges="['WRITE_INVOICES']">
                      <a
                        *appIfExternalAccountingEnabled="'Sage 50 US'"
                        mat-menu-item
                        [matMenuTriggerFor]="sage50UsInvoicing"
                      >Sage 50 US</a>
                      <a
                        *appIfExternalAccountingEnabled="'Sage 300'"
                        mat-menu-item
                        [matMenuTriggerFor]="sage300Invoicing"
                        >Sage 300</a>
                      <a
                      *appIfExternalAccountingEnabled="'QuickBooks Online'"
                      mat-menu-item
                      [matMenuTriggerFor]="quickbooksOnlineInvoicing"
                      >Quickbooks Online</a>
                    </ng-container>
<!--
                    <a
                      mat-menu-item
                      routerLink="invoices/export"
                      *ngIf="userPrivileges.includes('READ_EMPLOYEES')"
                      >Export Invoices</a
                    >
-->
                  </mat-menu>
                  <a mat-tab-link [matMenuTriggerFor]="invoicing" *appIfHasPrivileges="['READ_INVOICES', 'UI_VIEW_APP_INVOICES']">Invoicing</a>
                  <mat-menu #sage50UsInvoicing="matMenu">
                    <a
                      mat-menu-item
                      routerLink="accounting/sage50Us/export-invoices">Export Invoices</a>
                  </mat-menu>

                  <mat-menu #sage300Invoicing="matMenu">
                    <a
                      mat-menu-item
                      routerLink="accounting/sage300/export-invoices">Export Invoices</a>
                  </mat-menu>

                  <mat-menu #quickbooksOnlineInvoicing="matMenu">
                    <a
                      mat-menu-item
                      routerLink="accounting/quickbooks-online/export-invoices">Export Invoices</a>
                  </mat-menu>

                  <mat-menu #dataMgmtMenu="matMenu">
                    <a mat-menu-item [matMenuTriggerFor]="company" *appIfHasPrivileges="['UI_VIEW_APP_COMPANY']">Company</a>
                    <mat-divider></mat-divider>
                    <a mat-menu-item [matMenuTriggerFor]="accounting" *appIfHasPrivileges="['UI_VIEW_MENU_ACCOUNTING']">Accounting</a>
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="assignment-targets"
                      *appIfHasPrivileges="['READ_ASSIGNMENT_TARGETS', 'UI_VIEW_APP_ASSIGNMENT_TARGETS']"
                      >Assignment Targets</a
                    >
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="batch-reports"
                      *appIfHasPrivileges="['READ_BATCH_REPORTS', 'UI_VIEW_APP_BATCH_REPORTS']"
                      >Batch Reports</a
                    >
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="contacts"
                      *appIfHasPrivileges="['READ_CONTACTS', 'UI_VIEW_APP_CONTACTS']"
                      >Contacts</a
                    >
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="employees"
                      *appIfHasPrivileges="['READ_EMPLOYEES', 'UI_VIEW_APP_EMPLOYEES']"
                      >Employees</a
                    >
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="iot-gateways"
                      *appIfHasPrivileges="['READ_AWS_IOT_THINGS', 'UI_VIEW_APP_IOT_GATEWAYS']"
                      >IoT Gateways</a
                    >
                    <mat-divider></mat-divider>
                    <a mat-menu-item [matMenuTriggerFor]="mixDesigns" *appIfHasPrivileges="['UI_VIEW_MENU_MIX_DESIGNS']">Mix Designs</a>
                    <mat-divider></mat-divider>
                    <a mat-menu-item [matMenuTriggerFor]="pricing" *appIfHasPrivileges="['UI_VIEW_MENU_PRICING']">Pricing</a>
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      routerLink="tags"
                      *appIfHasPrivileges="['READ_EMPLOYEES']"
                    >Tags</a
                    >
                    <mat-divider></mat-divider>
                    <a
                      mat-menu-item
                      [matMenuTriggerFor]="users"
                      *appIfHasPrivileges="['UI_VIEW_MENU_USERS']"
                      >Users</a
                    >
                  </mat-menu>
                  <a mat-tab-link [matMenuTriggerFor]="dataMgmtMenu" *appIfHasPrivileges="['UI_VIEW_MENU_DATA_MANAGEMENT']">Data Management</a>

                  <mat-menu #company="matMenu">
                    <a
                      mat-menu-item
                      routerLink="my-company"
                      *appIfHasPrivileges="['READ_COMPANY', 'UI_VIEW_APP_COMPANY']"
                    >My Company</a
                    >
                    <a
                      mat-menu-item
                      [routerLink]="['my-company', 'settings']"
                      *appIfHasPrivileges="['READ_COMPANY', 'UI_VIEW_APP_COMPANY_SETTINGS']"
                    >Settings</a
                    >
                  </mat-menu>

                  <mat-menu #accounting="matMenu">
                    <a
                      mat-menu-item
                      routerLink="accounting-units"
                      *appIfHasPrivileges="['READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEMS']"
                      >Accounting Units</a>
                    <a
                      mat-menu-item
                      routerLink="invoice-terms"
                      *appIfHasPrivileges="['READ_INVOICE_TERMS', 'UI_VIEW_APP_INVOICE_TERMS']"
                      >Invoice Terms</a
                    >
                    <a
                      mat-menu-item
                      routerLink="taxes"
                      *appIfHasPrivileges="['READ_TAXES', 'UI_VIEW_APP_TAXES']"
                      >Taxes</a
                    >
                    <a
                      mat-menu-item
                      routerLink="tax-groups"
                      *appIfHasPrivileges="['READ_TAXES', 'UI_VIEW_APP_TAXES']"
                      >Tax Groups</a
                    >
                  </mat-menu>
                  <mat-menu #mixDesigns="matMenu" aria-label="Mix Design Submenu">
                    <a
                      mat-menu-item
                      routerLink="mix-designs"
                      *appIfHasPrivileges="['READ_MIX_DESIGNS', 'UI_VIEW_APP_MIX_DESIGNS']"
                      >Mix Designs</a
                    >
                    <a
                      mat-menu-item
                      routerLink="mix-designs/ingredients"
                      *appIfHasPrivileges="['READ_MIX_DESIGN_INGREDIENTS', 'UI_VIEW_APP_MIX_DESIGNS']"
                      >Mix Design Ingredients</a
                    >
                    <a
                      mat-menu-item
                      routerLink="mix-designs/tree"
                      *appIfHasPrivileges="['READ_MIX_DESIGN_TREE', 'UI_VIEW_APP_MIX_DESIGNS']"
                      >Mix Design Tree</a
                    >
                  </mat-menu>
                  <mat-menu #pricing="matMenu">
                    <a
                      mat-menu-item
                      routerLink="billable-items"
                      *appIfHasPrivileges="['READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEMS']"
                    >Billable Items</a
                    >
                    <a
                      mat-menu-item
                      routerLink="billable-items/associations"
                      *appIfHasPrivileges="['READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEM_ASSOCIATIONS']"
                    >Billable Item Associations</a
                    >
                    <a
                      mat-menu-item
                      routerLink="price-levels"
                      *appIfHasPrivileges="['READ_PRICE_LEVELS', 'UI_VIEW_APP_PRICE_LEVELS']"
                      >Price Levels</a
                    >
                  </mat-menu>
                  <mat-menu #users="matMenu">
                    <a
                      mat-menu-item
                      routerLink="users"
                      *appIfHasPrivileges="['READ_USERS', 'UI_VIEW_APP_USERS']"
                      >Users</a
                    >
                    <a
                      mat-menu-item
                      routerLink="roles"
                      *appIfHasPrivileges="['READ_ROLES', 'UI_VIEW_APP_USER_ROLES']"
                      >Roles</a
                    >
                  </mat-menu>
                <!-- </div> -->

              <span class="spacer"></span>
            </nav>
          </div>
          <!-- Middle Section Left Aligned -->

          <!-- Added padding here to move right menubar button down slightly -->
          <div class="flex-grow-0">
            <!-- Far Right -->

            <!--
            <button mat-icon-button>
              <mat-icon color="warn">network_check</mat-icon>
            </button>
            -->

            <mat-menu #profileMenu="matMenu">
              <button mat-menu-item routerLink="/app/user/profile"><mat-icon>account_box</mat-icon>My Profile</button>
              <button mat-menu-item routerLink="my-profile/change-password">
                <mat-icon>autorenew</mat-icon>Change Password
              </button>
              @if (userHasAccessToMoreThanOneTenant()) {
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/app/user/switch-tenant">
                  <mat-icon fontSet="material-symbols-outlined">domain</mat-icon>
                  Switch Tenant
                </button>
              }
              <mat-divider></mat-divider>
              <button mat-menu-item routerLink="/app/user/settings"><mat-icon>settings</mat-icon>Settings</button>
              <mat-divider></mat-divider>
              <app-connection-monitor></app-connection-monitor>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="printPage()">
                <mat-icon>print</mat-icon>Print Page
              </button>
              <mat-divider></mat-divider>
              <a mat-menu-item [matMenuTriggerFor]="tools"><mat-icon>build</mat-icon>Tools</a>
              <mat-menu #tools="matMenu">
                <a mat-menu-item (click)="openVolumeCalculatorDialog()">
                  <mat-icon>calculate</mat-icon>Volume Calculator
                </a>
              </mat-menu>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="reportIssue()"><mat-icon>feedback</mat-icon>Report an Issue</button>
              <mat-divider></mat-divider>
              <button mat-menu-item [matMenuTriggerFor]="policies"><mat-icon>policy</mat-icon>Policies</button>
              <mat-menu #policies="matMenu">
                <a mat-menu-item href="https://concretecloudops.io/legal/privacy/" target="_blank">Privacy Policy</a>
                <a mat-menu-item href="https://concretecloudops.io/legal/tos/" target="_blank">Terms of Service</a>
              </mat-menu>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="onAbout()"><mat-icon>info</mat-icon>About</button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="onLogout()">
                <mat-icon>exit_to_app</mat-icon>Logout
              </button>
            </mat-menu>
            <button fxShow fxHide.lt-sm mat-button [matMenuTriggerFor]="profileMenu" class="cco-user-menu">
              <mat-icon style="vertical-align: baseline;">account_box</mat-icon> Hello, {{ givenName() }}!
            </button>
            <button fxHide fxShow.lt-sm mat-icon-button [matMenuTriggerFor]="profileMenu">
              <mat-icon style="vertical-align: baseline;">account_box</mat-icon>
            </button>
            <button mat-button (click)="onLogout()" matTooltip="Logout" fxShow fxHide.lt-sm>
              <mat-icon style="vertical-align: baseline;">exit_to_app</mat-icon>
            </button>
          </div>
          <!-- Far Right -->
        </div>
      </mat-toolbar>
    }
  </ng-container>
  <ng-template #notAuthenticated>
    <div>
      <mat-toolbar color="primary" class="mat-elevation-z5">
        <a mat-button (click)="onLogin()"> <mat-icon>home</mat-icon> {{ appName }} </a>
        <span class="spacer"></span>
      </mat-toolbar>
    </div>
  </ng-template>

  <!-- Side Nav -->
  <mat-sidenav-container fxFlex="grow" fxFlexFill>
    <mat-sidenav
      mode="over"
      #sidenav
      [(opened)]="sidenavOpened"
      style="position: fixed; top: 56px; bottom: 0"
    >
      <app-side-nav-expandable-menu title="Dashboards" [requiredPrivileges]="['UI_VIEW_MENU_DASHBOARDS']">
        <!-- <app-side-nav-menu-item
          requiredPrivilege="READ_JOBS"
          routerLink="dashboard"
          (click)="closeSidenav()"
        >
          Main Dashboard</app-side-nav-menu-item
        > -->
        <app-side-nav-menu-item
          [requiredPrivileges]="['UI_VIEW_APP_OPERATOR_DASHBOARD']"
          routerLink="dashboard/operator"
          (click)="closeSidenav()"
        >
          Operator Dashboard</app-side-nav-menu-item
        >
      </app-side-nav-expandable-menu>
      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_TRUCKS', 'UI_VIEW_APP_TRUCKS']"
        routerLink="trucks"
        (click)="closeSidenav()"
        >Trucks
      </app-side-nav-menu-item>
      <app-side-nav-expandable-menu title="Booking" [requiredPrivileges]="['UI_VIEW_MENU_BOOKING']">
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_JOBS', 'UI_VIEW_APP_JOB_SCHEDULER']"
          routerLink="job-scheduler"
          (click)="closeSidenav()"
        >
          Job Scheduler</app-side-nav-menu-item
        >
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_ASSIGNMENTS', 'UI_VIEW_APP_ASSIGNMENT_SCHEDULER']"
          routerLink="assignment-scheduler"
          (click)="closeSidenav()"
        >
          Assignment Scheduler</app-side-nav-menu-item
        >
      </app-side-nav-expandable-menu>
      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_CUSTOMERS', 'UI_VIEW_APP_CUSTOMERS']"
        routerLink="customers"
        (click)="closeSidenav()"
      >
        Customers</app-side-nav-menu-item
      >

      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_PROJECTS', 'UI_VIEW_APP_PROJECTS']"
        routerLink="projects"
        (click)="closeSidenav()"
        >Projects
      </app-side-nav-menu-item>
      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_JOBS', 'UI_VIEW_APP_JOBS']"
        routerLink="jobs"
        (click)="closeSidenav()"
        >Jobs
      </app-side-nav-menu-item>
      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_ASSIGNMENTS', 'UI_VIEW_APP_ASSIGNMENTS']"
        routerLink="assignments"
        (click)="closeSidenav()"
      >
        Assignments</app-side-nav-menu-item
      >
      <app-side-nav-menu-item
        [requiredPrivileges]="['READ_DELIVERY_SLIPS', 'UI_VIEW_APP_DELIVERY_SLIPS']"
        routerLink="delivery-slips"
        (click)="closeSidenav()"
        >Delivery Slips</app-side-nav-menu-item
      >
      <app-side-nav-expandable-menu title="Invoicing" [requiredPrivileges]="['UI_VIEW_APP_INVOICES']">
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_INVOICES', 'UI_VIEW_APP_INVOICES']"
          routerLink="invoices"
          (click)="closeSidenav()"
          >Invoices</app-side-nav-menu-item
        >
        <ng-container *appIfHasPrivileges="['WRITE_INVOICES']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['WRITE_INVOICES']"
            routerLink="invoices/create"
            (click)="closeSidenav()"
            >Create Invoices</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            *appIfExternalAccountingEnabled="'Sage 50 US'"
            [requiredPrivileges]="['WRITE_INVOICES']"
            routerLink="invoices/export"
            (click)="closeSidenav()"
            >Export Invoices</app-side-nav-menu-item
          >
          <app-side-nav-expandable-menu
            *appIfExternalAccountingEnabled="'Sage 50 US'"
            title="Sage 50 US">
            <app-side-nav-menu-item
              [requiredPrivileges]="['WRITE_INVOICES']"
              routerLink="accounting/sage50Us/export-invoices"
              (click)="closeSidenav()"
            >Export Invoices</app-side-nav-menu-item>
          </app-side-nav-expandable-menu>
          <app-side-nav-expandable-menu
            *appIfExternalAccountingEnabled="'Sage 300'"
            title="Sage 300">
            <app-side-nav-menu-item
              [requiredPrivileges]="['WRITE_INVOICES']"
              routerLink="accounting/sage300/export-invoices"
              (click)="closeSidenav()"
              >Export Invoices</app-side-nav-menu-item>
          </app-side-nav-expandable-menu>
          <app-side-nav-expandable-menu
            *appIfExternalAccountingEnabled="'QuickBooks Online'"
            title="Quickbooks Online">
            <app-side-nav-menu-item
              [requiredPrivileges]="['WRITE_INVOICES']"
              routerLink="accounting/quickbooks-online/export-invoices"
              (click)="closeSidenav()"
              >Export Invoices</app-side-nav-menu-item>
          </app-side-nav-expandable-menu>
        </ng-container>
      </app-side-nav-expandable-menu>
      <!--
          <a mat-menu-item routerLink="" (click)="closeSidenav()">Dashboard</a>
          <mat-divider></mat-divider>
          -->
      <!--
          <a mat-menu-item routerLink="batch-reports" *ngIf="userPrivileges.includes('READ_BATCH_REPORTS')" (click)="closeSidenav()">Batch Reports</a>
          <a mat-menu-item routerLink="delivery-slips/new" *ngIf="userPrivileges.includes('WRITE_DELIVERY_SLIPS')" (click)="closeSidenav()">New Delivery Slip</a>
          <a mat-menu-item routerLink="delivery-slips" *ngIf="userPrivileges.includes('READ_DELIVERY_SLIPS')" (click)="closeSidenav()">Delivery Slips</a>
          -->

      <app-side-nav-expandable-menu title="Data Management" class="dataManagement" [requiredPrivileges]="['UI_VIEW_MENU_DATA_MANAGEMENT']">
        <app-side-nav-expandable-menu title="Company" [requiredPrivileges]="['UI_VIEW_MENU_COMPANY']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_COMPANY', 'UI_VIEW_APP_COMPANY']"
            routerLink="my-company"
            (click)="closeSidenav()"
          >My Company</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_COMPANY', 'UI_VIEW_APP_COMPANY_SETTINGS']"
            [routerLink]="['my-company', 'settings']"
            (click)="closeSidenav()"
          >Settings</app-side-nav-menu-item
          >
        </app-side-nav-expandable-menu>
        <mat-divider></mat-divider>
        <app-side-nav-expandable-menu title="Accounting" class="accounting" [requiredPrivileges]="['UI_VIEW_MENU_ACCOUNTING']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_BILLABLE_ITEMS']"
            routerLink="accounting-units"
            (click)="closeSidenav()"
            >Accounting Units
          </app-side-nav-menu-item>
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_INVOICE_TERMS', 'UI_VIEW_APP_INVOICE_TERMS']"
            routerLink="invoice-terms"
            (click)="closeSidenav()"
            >Invoice Terms
          </app-side-nav-menu-item>
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_TAXES', 'UI_VIEW_APP_TAXES']"
            routerLink="taxes"
            (click)="closeSidenav()"
            >Taxes
          </app-side-nav-menu-item>
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_TAXES', 'UI_VIEW_APP_TAXES']"
            routerLink="tax-groups"
            (click)="closeSidenav()"
            >Tax Groups
          </app-side-nav-menu-item>
        </app-side-nav-expandable-menu>
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_ASSIGNMENT_TARGETS', 'UI_VIEW_APP_ASSIGNMENT_TARGETS']"
          routerLink="assignment-targets"
          (click)="closeSidenav()"
          >Assignment Targets
        </app-side-nav-menu-item>
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_BATCH_REPORTS', 'UI_VIEW_APP_BATCH_REPORTS']"
          routerLink="batch-reports"
          (click)="closeSidenav()"
          >Batch Reports</app-side-nav-menu-item
        >
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_CONTACTS', 'UI_VIEW_APP_CONTACTS']"
          routerLink="contacts"
          (click)="closeSidenav()"
        >
          Contacts</app-side-nav-menu-item
        >
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_EMPLOYEES', 'UI_VIEW_APP_EMPLOYEES']"
          routerLink="employees"
          (click)="closeSidenav()"
        >
          Employees</app-side-nav-menu-item
        >
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_AWS_IOT_THINGS', 'UI_VIEW_APP_IOT_GATEWAYS']"
          routerLink="iot-gateways"
          (click)="closeSidenav()"
          >IoT Gateways</app-side-nav-menu-item
        >
        <app-side-nav-expandable-menu title="Mix Designs" class="mix-designs" [requiredPrivileges]="['UI_VIEW_MENU_MIX_DESIGNS']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_MIX_DESIGNS', 'UI_VIEW_APP_MIX_DESIGNS']"
            routerLink="mix-designs"
            (click)="closeSidenav()"
          >
            Mix Designs</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_MIX_DESIGN_INGREDIENTS', 'UI_VIEW_APP_MIX_DESIGNS']"
            routerLink="mix-designs/ingredients"
            (click)="closeSidenav()"
            >Mix Design Ingredients</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_MIX_DESIGN_TREE', 'UI_VIEW_APP_MIX_DESIGNS']"
            routerLink="mix-designs/tree"
            (click)="closeSidenav()"
            >Mix Design Tree</app-side-nav-menu-item
          >
        </app-side-nav-expandable-menu>
        <app-side-nav-expandable-menu title="Pricing" class="pricing" [requiredPrivileges]="['UI_VIEW_MENU_PRICING']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEMS']"
            routerLink="billable-items"
            (click)="closeSidenav()"
            >Billable Items</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEM_ASSOCIATIONS']"
            routerLink="billable-items/associations"
            (click)="closeSidenav()"
          >Billable Item Associations</app-side-nav-menu-item
          >
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_PRICE_LEVELS', 'UI_VIEW_APP_PRICE_LEVELS']"
            routerLink="price-levels"
            (click)="closeSidenav()"
            >Price Levels</app-side-nav-menu-item
          >
        </app-side-nav-expandable-menu>
        <app-side-nav-menu-item
          [requiredPrivileges]="['READ_CUSTOMERS']"
          routerLink="tags"
          (click)="closeSidenav()"
        >Tags</app-side-nav-menu-item
        >
        <app-side-nav-expandable-menu title="Users" class="users" [requiredPrivileges]="['UI_VIEW_MENU_USERS']">
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_USERS', 'UI_VIEW_APP_USERS']"
            routerLink="users"
            (click)="closeSidenav()"
            >Users
          </app-side-nav-menu-item>
          <app-side-nav-menu-item
            [requiredPrivileges]="['READ_ROLES', 'UI_VIEW_APP_USER_ROLES']"
            routerLink="roles"
            (click)="closeSidenav()"
          >Roles</app-side-nav-menu-item>
        </app-side-nav-expandable-menu>
      </app-side-nav-expandable-menu>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" style="min-height: calc(100vh - 64px - 1.75em)">
      <div fxLayout="row" fxFlex fxFlexFill>
        <div fxFlex fxFlexFill>
          <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
          </mat-tab-nav-panel>
        </div>
        <!-- <div fxFlex="40px" style="border-left: 1px lightgrey solid" #sidenavToggleBar>
          <div style="min-height: 10px"></div>
          <button mat-icon-button (click)="helpSidenav.toggle()"><mat-icon>info</mat-icon></button>
        </div> -->
      </div>
    </mat-sidenav-content>
    <!-- <mat-sidenav
      mode="side"
      #helpSidenav
      position="end"
      style="min-width: 20em; background: transparent; border: none; font-family: Roboto"
    >
      <div style="min-height: 8px"></div>
      <h3>Contextual Help</h3>
      <p>Many useful tips!</p>
    </mat-sidenav> -->
  </mat-sidenav-container>
  <div class="status-bar no-print" *ngIf="authenticated$ | async">
    <app-status-bar></app-status-bar>
  </div>
</div>

